<template>
  
  <div class="ls">
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div> 
  </div>
 
</template>


<script setup>
 
</script>


<style scoped>

.ls{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}
.rw{  
  aspect-ratio: 1/1;
  width: calc(100% / 5 - 5px);
} 

 

@container pb (max-width: 600px) {

.rw {
  width: calc(100% / 4 - 5px);
}

}

@container pb (max-width: 600px) {

.rw {
  width: calc(100% / 3 - 5px);
}

}

@container pb (max-width: 500px) {

.rw {
  width: calc(100% / 2 - 5px);
}

}

</style>
